interface AuthConfig {
  clientId: string
  redirectUri: string
  userInfo: string
  scopes: string[]
}
interface BaseConfig {
  appFullName: string
  appName: string
  description: string
  isProd: boolean
  oAuth: AuthConfig
}

export const config: BaseConfig = {
  appFullName: 'Middle Mile TOP UI',
  appName: 'Middle Mile TOP UI',
  description: 'AMiddle Mile TOP UI',
  isProd: process.env.REACT_APP_NODE_ENV === 'production',
  oAuth: {
    clientId: 'nike.logistics.tms-admin-tool',
    redirectUri: window.location.origin,
    userInfo: process.env.REACT_APP_USER_INFO,
    scopes: [
      'transportation:tms.admin_tool.rules::read: transportation:tms.admin_tool.rules::create: transportation:tms.admin_tool.rules::update: transportation:tms.admin_tool.rules::delete: transportation:tms.admin_tool.rules::execute: profile openid email'
    ]
  }
};
