import { useEffect, useState } from 'react';
import { NikeApp } from '@nike/nike-design-system-icons';
import { AppHeader, Avatar, Text, Menu, MenuItem } from '@nike/eds';
import { useNavigate } from 'react-router-dom';

interface CurrentUser {
  firstName: string
  lastName: string
  initials: string
  email: string
}
function Header (props: { loggedInUser: any, handleLogout: any }): JSX.Element {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState<CurrentUser>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [open, setOpen] = useState(false);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  useEffect(() => {
    const splitEmail = props.loggedInUser.split('.')
    const initials = ((splitEmail[0]).charAt(0) + (splitEmail[1]).charAt(0)).toUpperCase();
    const userDetails = {
      firstName: (splitEmail[0]),
      lastName: (splitEmail[1]).split('@')[0],
      initials,
      email: props.loggedInUser
    }
    setUserDetails(userDetails)
  }, [])

  const DropdownMenuPlaceholder = (): JSX.Element => (
    <>
      <Menu isOpen={open} placement={'bottom-start'} bodySlot={undefined} >
        <Text style={{
          color: '#dedede',
          cursor: 'pointer',
          padding: '12px 2px 12px 2px'
        }}
          onClick={() => { navigate('/') }}
          font={'subtitle-2'}
          as="span"
          className={window.location.pathname === '/' ? 'activeHeaderClass' : ''}
        >
          Home
        </Text>
      </Menu>
    </>
  );
  const userMenuHeader = (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '16px'
      }}
    >
      <Avatar
        initials={userDetails?.initials ? userDetails.initials : ''}
        size={48}
        url=""
      />
   <div style={{ display: 'inline-flex', flexDirection: 'column' }}>
        <Text font="title-5">{userDetails?.firstName && userDetails.lastName ? userDetails.firstName + ' ' + userDetails.lastName : ''}</Text>
        <Text font="body-3">{userDetails?.email ? userDetails.email : ''}</Text>
      </div>
    </div>
  );
  function logout (): void {
    props.handleLogout(true)
    navigate('/logout', {
      state: {
      }
    });
  }
  const userMenuBody = (
    <>
      <MenuItem onClick={() => logout()}>Logout</MenuItem>
    </>
  );

  const UserMenuPlaceholder = (): JSX.Element => (
    <>
      <Menu
        onClickOutside={() => {
          setUserMenuOpen(!userMenuOpen);
        }}
        isOpen={userMenuOpen}
        bodySlot={userMenuBody}
        headerSlot={userMenuHeader}
        placement={'bottom-start'}
      >
        <Avatar
          size={48}
          initials={userDetails?.initials ? userDetails.initials : ''}
          onClick={() => setUserMenuOpen(!userMenuOpen)}
        />

      </Menu>
    </>
  );

  return (
    <>
      <AppHeader
        isDark
        appName="Middle Mile Transportation Ops Portal"
        logoSlot={<NikeApp color={'white'} width={'56px'} height={'64px'} />}
        commandBarSlot={<DropdownMenuPlaceholder />}
        avatarSlot={<UserMenuPlaceholder />}
      />
    </>
  );
}
export default Header
