import { Text } from '@nike/eds';

function HomePage (): JSX.Element {
  return (
    <>
        <Text font={'title-5'} as={'h5'}>
        Dashboard
      </Text>
    </>
  );
}

export default HomePage
